import React, { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight, RotateCw } from "lucide-react";
import { FaRegStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getuserIncomingMails, toggleFavoriteStatus, moveAllEmailToTrash,
  toggleArchiveStatus,
  moveToTrash
 } from "../../Api/ExportApi";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { MetroSpinner } from "react-spinners-kit";
import { toast } from "react-toastify";
import MarkAllRead from "../../Components/Ui/MarkAllRead";
import useCheckboxHandler from "../../hooks/useCheckboxHandler";
import { useAppContext } from '../../context/AppContext'; 
import { IoTimeOutline, IoTrashOutline } from "react-icons/io5";
import {
  MdArchive,
  MdOutlineArchive,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineChevronLeft,
  MdOutlineChevronRight,
  MdOutlineStarBorderPurple500,
} from "react-icons/md";

const Inbox = () => {
  const [inboxid, setInboxid] = useState(null);
  const [incomingEmails, setIncomingEmails] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [isLoading, setLoading] = useState(false);
  const [favoriteEmails, setFavoriteEmails] = useState({});
  const [userId, setUserId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const { selectAllCheckbox, selectedItems, setSelectAllCheckbox, setSelectedItems, handleCheckboxClick } = useCheckboxHandler(incomingEmails);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const userId = decodedToken.userId;
          setUserId(userId);
          fetchIncomingEmails(1);
        } catch (err) {
          console.error("Error fetching user data:", err.message);
        }
      } else {
        console.log("No token found");
      }
    };

    fetchUserData();
  }, []);

  const fetchIncomingEmails = async (page = 1) => {
    setLoading(true);
    try {
      const resp = await getuserIncomingMails(page);
      setIncomingEmails(resp.data.emails);
      setTotalItems(resp?.data?.total || 0);
      setItemPerPage(resp?.data?.limit);
      setSelectAllCheckbox(false);
      setSelectedItems([]);
    } catch (err) {
      console.error("Error fetching incoming emails:", err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleFavoriteStatus = async (emailId) => {
    try {
      // Call API to toggle favorite status
      await toggleFavoriteStatus(emailId);

      // Update the favorite status in the local state
      setFavoriteEmails((prevFavorites) => ({
        ...prevFavorites,
        [emailId]: !prevFavorites[emailId], // Toggle the favorite status
      }));

      toast.success("Email favorite status updated");
      fetchIncomingEmails(currentPage); // Refetch emails after the update
    } catch (error) {
      toast.error("Failed to update favorite status");
      console.error("Error updating favorite status:", error);
    }
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      fetchIncomingEmails(newPage);
    }
  };

  const handleRefresh = () => {
    fetchIncomingEmails(currentPage);
  };

  const handleDeleteAll = async () => {
    try {
      await moveAllEmailToTrash([...selectedItems]);
      if (selectedItems.length === incomingEmails?.length) {
        if (currentPage > 1) {
          fetchIncomingEmails(currentPage - 1);
        }
      } else {
        fetchIncomingEmails(currentPage);
      }
    } catch (error) {
      toast.error("Failed to delete email.");
      console.error("Error deleting email:", error);
    }
  };
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };


  const handleToggleArchiveStatus = async (emailId) => {
    try {
      const resp = await toggleArchiveStatus(emailId);
      if (
        resp.message === "Email added to archive" ||
        "Email removed from archive"
      ) {
        fetchIncomingEmails(currentPage);
        toast.success("Email archive status updated");
      } else {
        toast.error("Failed to update archive status");
      }
    } catch (error) {
      toast.error("Failed to update archive status", "danger");
      console.error("Error updating archive status:", error);
    }
  };
  const handleMoveToTrash = async (emailId, redirect) => {
    try {
      await moveToTrash(emailId);
      toast.success("Email moved to trash");
      if (redirect) {
        navigate("/trash");
      } else {
        fetchIncomingEmails(currentPage);
      }
    } catch (error) {
      toast.error("Failed to move email to trash");
      console.error("Error moving email to trash:", error);
    }
  };

  return (
    <div className="inbox">
      <div className="mx-auto">
        <div className="inbox-main w-full mx-auto">
          <div className="bg-[#fff] rounded-md shadow mb-2">
            <table className="w-full">
              {/* <thead>
                <tr>
                  <td>
                    <div className="flex items-center gap-2 relative">
                      <button onClick={handleRefresh}>
                        <RotateCw className="w-[20px] h-[20px] text-black" />
                      </button>
                      {selectedItems?.length > 1 && (
                        <button
                          className="font-[500] text-gray-400 hover:bg-gray-200 p-1 rounded-sm"
                          onClick={handleDeleteAll}
                        >
                          Trash All
                        </button>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="flex justify-end gap-2 mt-2">
                      <div>
                        <p className="text-base text-black">
                          {startItem}-{endItem} of {totalItems}
                        </p>
                      </div>
                      <div>
                        <button
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          <ChevronLeft className="w-[20px] h-[20px] text-black" />
                        </button>
                        <button
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        >
                          <ChevronRight className="w-[20px] h-[20px] text-black" />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </thead> */}
                 <thead>
                <tr>
                  <td>
                    <div className="flex items-center gap-2 relative">
                      <div className="dropdown inline-block relative">
                        <button className="text-gray-700 font-semibold py-2 rounded inline-flex items-center">
                          <span className="mr-1">
                            {/* <MdOutlineCheckBoxOutlineBlank className="text-xl text-black" /> */}
                            <input
                              checked={selectAllCheckbox}
                              type="checkbox"
                              onChange={(e) => handleCheckboxClick(e, "toggle")}
                            />
                          </span>
                          <svg
                            className="fill-current h-4 w-4 text-black"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </button>
                        <ul className="dropdown-menu absolute text-gray-700 pt-1 hidden group-hover:block">
                          <li
                            className="rounded-t cursor-pointer text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 block whitespace-no-wrap"
                            onClick={(e) => handleCheckboxClick(e, "all")}
                          >
                            All
                          </li>
                          <li
                            className="bg-white text-sm cursor-pointer font-medium hover:!bg-[#dae2e8] py-2 px-4 block whitespace-no-wrap"
                            onClick={(e) => handleCheckboxClick(e, "none")}
                          >
                            None
                          </li>
                          <li
                            className="rounded-b text-sm cursor-pointer bg-white font-medium text-gray-700 hover:!bg-[#dae2e8] py-2 px-4 block whitespace-no-wrap"
                            onClick={(e) => handleCheckboxClick(e, "read", null, userId)}
                          >
                            Read
                          </li>
                          <li
                            className="rounded-b text-sm cursor-pointer bg-white font-medium text-gray-700 hover:!bg-[#dae2e8] py-2 px-4 block whitespace-no-wrap"
                            onClick={(e) => handleCheckboxClick(e, "unread", null, userId)}
                          >
                            Unread
                          </li>
                          <li
                            className="rounded-b text-sm cursor-pointer bg-white font-medium text-gray-700 hover:!bg-[#dae2e8] py-2 px-4 block whitespace-no-wrap"
                            onClick={(e) => handleCheckboxClick(e, "starred", null, userId)}
                          >
                            Starred
                        </li>
                        </ul>
                      </div>

                      <button onClick={handleRefresh}>
                        <RotateCw className="w-[20px] h-[20px] text-black" />
                      </button>
                      <MarkAllRead  emails={incomingEmails} currentPage={currentPage} refetchEmails={incomingEmails} selectedItems={selectedItems} userId={userId} selectAllCheckbox={selectAllCheckbox}/>
                      {selectedItems?.length > 1 && (
                        <button
                          className="font-[500] text-gray-400 hover:bg-gray-200 p-1 rounded-sm"
                          onClick={handleDeleteAll}
                        >
                          Trash All
                        </button>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="flex justify-end gap-2 mt-2">
                      <div>
                        <p className="text-base text-black">
                          {startItem}-{endItem} of {totalItems}
                        </p>
                      </div>
                      <div>
                        <button
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          <ChevronLeft className="w-[20px] h-[20px] text-black" />
                        </button>
                        <button
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        >
                          <ChevronRight className="w-[20px] h-[20px] text-black" />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </thead>
            </table>
          </div>

          <div className="bg-[#fff] rounded-md shadow">
            <table className="w-full">
              <tbody>
                {isLoading ? (
                  <tr style={{ textAlign: "center" }}>
                    <td
                      colSpan="3"
                      className="loader-icon"
                      style={{
                        textAlign: "center",
                        padding: "80px 0",
                        display: "inline-block",
                      }}
                    >
                      <MetroSpinner size={50} color="#000000" />
                    </td>
                  </tr>
                ) : (
                  <>
                    {incomingEmails?.length > 0 ? (
                      incomingEmails?.map((email) => {
                        const plainTextBody = stripHtmlTags(email?.body);
                        return (
                          <tr
                            key={email?._id}
                            className={`rounded-t-md border-b hover:bg-[#063c6626] cursor-pointer ${
                              email.readBy.includes(userId) ? "bg-[#063c6626]" : "bg-[#f5f5f5]"
                            }`}
                          >
                            <td className="w-[2%]">
                              <span>
                                <input
                                  type="checkbox"
                                  checked={
                                    selectAllCheckbox ||
                                    selectedItems?.includes(email?._id)
                                  }
                                   name="checkbox"
                                  onChange={(e) =>
                                    handleCheckboxClick(e, "single", email?._id)
                                  }
                                />
                              </span>
                            </td>
                            <div className="flex justify-between flex-wrap" 
                           
                            >
                            <td className="md:px-0 px-[15px] md:w-[60%]"   onClick={() =>
                              navigate(`/inbox-single/${email?._id}`)
                            }>
                              <p className="text-black font-medium text-base break-all">
                                {email?.subject}
                              </p>

                              <p className="text-grey-700 text-sm break-all">
                                {plainTextBody}
                              </p>
                            </td>
                            <td className="md:w-[30%]">
                              <p className="flex items-center gap-2 justify-end text-[#063c66] font-medium text-sm mb-0">
                              <button
                                    onClick={() =>
                                      handleToggleArchiveStatus(email?._id)
                                    }
                                  >
                                    {email?.archivedBy &&
                                    email?.archivedBy?.includes(userId) ? (
                                      <MdArchive size={20} />
                                    ) : (
                                      <MdOutlineArchive className="text-xl text-gray-400" />
                                    )}
                                  </button>
                            
                                  <button
                                    onClick={() =>
                                      handleMoveToTrash(email?._id, false)
                                    }
                                  >
                                    <IoTrashOutline className="text-xl text-gray-400" />
                                  </button>
                                {moment(email?.sentEmail?.sentAt).format(
                                  "MMMM D"
                                )}
                                <button
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleToggleFavoriteStatus(email?._id);
                                  }}
                                  className={`w-[18px] h-[18px] ${
                                    email?.starredBy &&
                                    email?.starredBy?.includes(userId)
                                      ? "text-yellow-500"
                                      : "text-gray-500"
                                  }`}
                                >
                                  <FaRegStar />
                                </button>
                              </p>
                            </td>
                            </div>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center py-4">
                          No emails found
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Inbox;
