import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useCheckboxHandler from "../../Components/hooks/useCheckboxHandler";
import {
  deleteDomain,
  domainStatusUpdate,
  domainStatusVerify,
  getArchiveEmails,
  getDomainsByUser,
  verifyUserDomain,
} from "../../Api/ExportApi";
import { jwtDecode } from "jwt-decode";
import ToastHandle from "../../Components/helpers/ToastMessage";
import { FaRegTrashAlt } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import ConfirmModal from "../../Components/ConfirmModal";
import { toast } from "react-toastify";

const Domains  = () => {
 const [archivedEmails, setArchivedEmails] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [isLoading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [domain, setDomain] = useState("");
  const [userDomains, setUserDomains] = useState([]);
  const [domainLoading, setDomainLoading] = useState(false);
  const [addDomainModal, setAddDomainModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [domainId, setDomainId] = useState(false);

  const { setSelectAllCheckbox, setSelectedItems } =
    useCheckboxHandler(archivedEmails);

  const navigate = useNavigate();

  const getDomains = async () => {
    try {
      const resp = await getDomainsByUser();
      if (resp.status === 200) {
        setUserDomains(resp?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteDomain = async () => {
    try {
      const resp = await deleteDomain(domainId);

      if (resp.status === 200) {
        // ToastHandle("Domain Deleted Successfully", "success");
        toast.success("Domain Deleted Successfully");
        getDomains();
        setConfirmModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const userId = decodedToken.userId;

          // const resp = await userDatabyid(userId);
          setUserId(userId);
        } catch (err) {
          console.error("Error fetching user data:", err.message);
        }
      } else {
        console.log("No token found");
      }
    };

    fetchUserData();
    getDomains();
  }, []);

  const fetchArchivedEmails = async (page) => {
    setLoading(true);
    try {
      const resp = await getArchiveEmails(page);

      setArchivedEmails(resp.data.emails);
      setTotalItems(resp?.data?.total || 0);
      setItemPerPage(resp?.data?.limit);
      setSelectAllCheckbox(false);
      setSelectedItems([]);
    } catch (err) {
      //   toast.error("Failed to fetch emails");
      console.error("Error fetching incoming emails:", err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArchivedEmails(currentPage);
  }, [currentPage]);

  const handleAddDomain = async () => {
    setDomainLoading(true);
    if (typeof window.entri === "undefined") {
      console.error("Entri SDK not loaded.");
      setDomainLoading(false);
      return;
    }

    try {
      const response = await verifyUserDomain(domain);

      if (response.status !== 200) {
        setDomainLoading(false);
        throw new Error("Failed to verify domain");
      }

      const data = await response.data;

      if (data.authToken && data.appId) {
        const config = {
          applicationId: data.appId,
          token: data.authToken,
          dnsRecords: data.config.dnsRecords,
        };

        window.entri.showEntri(config);
        setDomainLoading(false);
        getDomains();
        setDomain("");
        setAddDomainModal(false);
      }
    } catch (error) {
      console.log(error,"error>>>12");
      setDomainLoading(false);
      // ToastHandle(error.response?.data?.error?.message, "danger");
      toast.error(error.response?.data?.message);
      console.error("Error launching Entri:", error);
    }
  };

  async function handleOnEntriClose(event) {
    console.log("onEntriClose", event.detail);
    if (event?.detail?.success === true) {
      const resp = await domainStatusUpdate(event?.detail?.domain);

      if (resp.status === 200) {
        getDomains();
      }
    }
  }

  window.addEventListener("onEntriClose", handleOnEntriClose, false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const handleDomainVerify = async (domain) => {
    if (typeof window.entri === "undefined") {
      console.error("Entri SDK not loaded.");
      return;
    }

    try {
      const response = await domainStatusVerify(domain);

      if (response.status !== 200) {
        throw new Error("Failed to verify domain");
      }

      const data = await response.data;

      if (data.authToken && data.appId) {
        const config = {
          applicationId: data.appId,
          token: data.authToken,
          dnsRecords: data.config.dnsRecords,
        };

        window.entri.showEntri(config);
        getDomains();
      }
    } catch (error) {
      // ToastHandle(error.response?.data?.error?.message, "danger");
     toast.error(error.response?.data?.message);

      console.error("Error launching Entri:", error);
    }
  };
  return (
    <div className="relative">
    {confirmModal && <ConfirmModal handleConfirmModal={setConfirmModal} handleDeleteUser={handleDeleteDomain}/>}
    <div className="py-2">
      {/* <div className="bg-white inbox-header flex flex-wrap items-center justify-between px-3 pb-2">
        <div className="flex items-center gap-3 relative">
          <span className="block mt-1">
            <input
              checked={selectAllCheckbox && archivedEmails?.length > 0}
              type="checkbox"
              className="cursor-pointer"
              onChange={(e) => handleCheckboxClick(e, "toggle")}
            />
          </span>
          <div className="dropdown inline-block relative">
            <button className=" text-gray-700 font-semibold hover:bg-gray-200 text-gray-500 py-2 px-1 rounded inline-flex items-center">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
              </svg>
            </button>
            <ul className="dropdown-menu absolute hidden text-gray-700 pt-1">
              <li
                className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                onClick={(e) => handleCheckboxClick(e, "all")}
              >
                All
              </li>
              <li
                className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                onClick={(e) => handleCheckboxClick(e, "none")}
              >
                None
              </li>
              <li
                className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                onClick={(e) => handleCheckboxClick(e, "read", null, userId)}
              >
                Read
              </li>
              <li
                className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                onClick={(e) =>
                  handleCheckboxClick(e, "unread", null, userId)
                }
              >
                Unread
              </li>
              <li
                className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                onClick={(e) =>
                  handleCheckboxClick(e, "starred", null, userId)
                }
              >
                Starred
              </li>
              <li
                className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                onClick={(e) =>
                  handleCheckboxClick(e, "unstarred", null, userId)
                }
              >
                Unstarred
              </li>
            </ul>
          </div>
          <button onClick={handleRefresh}>
            <IoMdRefresh className="text-2xl  hover:bg-gray-200 text-gray-500 rounded" />
          </button>
          <MarkAllRead
            emails={archivedEmails}
            selectedItems={selectedItems}
            userId={userId}
            currentPage={currentPage}
            refetchEmails={fetchArchivedEmails}
            selectAllCheckbox={selectAllCheckbox}
          />
          {selectedItems?.length > 1 && (
            <button
              className="font-[500] text-gray-400 hover:bg-gray-200 p-1 rounded-sm"
              onClick={handleDeleteAll}
            >
              <IoTrashOutline
                size={24}
                className="text-xl text-gray-400 hidden md:block"
              />
            </button>
          )}
        </div>
        <div className="flex items-center gap-3">
          <p className="text-gray-500 font-normal text-base hover:bg-gray-200 p-2 rounded">
            {startItem}-{endItem} of {totalItems}
          </p>
          <div className="flex items-center">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <MdOutlineChevronLeft className="text-gray-800 text-xl hover:bg-gray-200 rounded" />
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <MdOutlineChevronRight className="text-gray-800 text-xl hover:bg-gray-200 rounded" />
            </button>
          </div>
        </div>
      </div> */}
      <div className="inbox-body border-t ">
        <div className="inbox-body-inner">
          <div className="flex flex-col">
            <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
              <div className="py-2 min-w-full">
                <div className="overflow-hidden">
                  <div className="flex justify-between items-center my-5">
                    <h2 className="text-3xl font-bold">Domain List</h2>
                    <button
                      type="button"
                      className="text-white bg-blue-700 hover:bg-blue-800 disabled:bg-blue-400 disabled:hover:bg-blue-400 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                      onClick={() => setAddDomainModal(true)}
                    >
                      Add domain
                    </button>
                  </div>
                  <div className="sm:overflow-x-hidden overflow-x-scroll">
                  <table className="min-w-full">
                    <thead className="bg-white border-b">
                      <tr>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Domain
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Active
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Created At
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {userDomains?.map((item, i) => (
                        <tr className="bg-gray-100 border-b" key={item?._id}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {i + 1}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {item?.domain}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {item?.verified ? (
                              <span className="text-blue-500 font-bold">
                                Verified
                              </span>
                            ) : (
                              <button
                                className="bg-red-500 text-white p-1 text-sm rounded-md"
                                onClick={() =>
                                  handleDomainVerify(item?.domain)
                                }
                              >
                                Not Verified
                              </button>
                            )}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {item?.createdAt && formatDate(item.createdAt)}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                         
                            <FaRegTrashAlt
                              className="cursor-pointer"
                              color="red"
                              onClick={() => {
                                setConfirmModal(true)
                                setDomainId(item?.domain)
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {addDomainModal && (
      <div className="fixed top-0 left-0 bg-[#00000073] w-full h-full flex justify-center items-center">
        <div className="bg-white w-[85%] sm:w-[40%] h-fit pb-5 rounded-xl pt-8 relative">
          <RxCross2
            size={30}
            className="absolute sm:top-5 top-2 sm:right-5 right-2 cursor-pointer"
            onClick={() => setAddDomainModal(false)}
          />
          <h1 className="sm:text-4xl text-2xl font-bold text-center mb-10">
            Add Your Domain
          </h1>
          <div className="flex flex-col justify-center items-center mt-16">
            <input
              id="domainName"
              name="domain"
              type="text"
              autoComplete="off"
              placeholder="Enter domain name"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              className="relative block w-[60%] mb-5 rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3"
            />

            <button
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 disabled:bg-blue-400 disabled:hover:bg-blue-400 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-10 py-3.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              onClick={handleAddDomain}
              disabled={domainLoading || domain?.trim() === ""}
            >
              {domainLoading ? "Loading..." : "Add domain"}
            </button>
          </div>
        </div>
      </div>
    )}
  </div>
  )
}

export default Domains