import { X } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { IoAttach } from "react-icons/io5";
import { userSendMail, userDatabyid, draftEmail } from "../../Api/ExportApi";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
 
import { IoMdClose } from "react-icons/io";
// import Quill from '../../utils/quillConfig';
// import 'quill-image-resize-module';

const ComposeEmail = () => {
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [from, setFrom] = useState("");
  const [userId, setUserId] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cc, setCc] = useState("");
  const [bcc, setBcc] = useState("");
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [isDrafting, setIsDrafting] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const userId = decodedToken.userId;

          const resp = await userDatabyid(userId);
          console.log(resp,"resp>>>>12");
          setFrom(resp?.data?.user?.email);
          setUserId(resp?.data?.user?._id);
          // setInboxid(resp?.data?.user?._id);
        } catch (err) {
          console.log(err.message);
        }
      } else {
        console.log("No token found");
      }
    };

    fetchUserData();
  }, []);

  // const handleSendEmail = async () => {
  //   setLoading(true);

  //   try {
  //     await userSendMail(to, subject, body, from, userId, attachments);
  //     toast.success("Email sent successfully!");
  //     setTo("");
  //     setSubject("");
  //     setBody("");
  //     setLoading(false);
  //     setAttachments([]);
  //   } catch (error) {
  //     setLoading(false);
  //     toast.error("Failed to send email");
  //     console.error("Error sending email:", error);
  //   }
  // };
  const handleSendEmail = async () => {
    setLoading(true);

    // Validation
    if (!to) {
      toast.error("Recipient email is required.");
      setLoading(false);
      return;
    }
    if (!subject) {
      toast.error("Subject is required.");
      setLoading(false);
      return;
    }
    if (!body) {
      toast.error("Email body is required.");
      setLoading(false);
      return;
    }

    // Regex for validating email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    console.log(to, "to>>>>>>>>");
    
    // Split the email addresses by comma and trim any extra spaces
    const emails = to.split(',').map(email => email.trim());
    
    // Validate each email
    for (let email of emails) {
      if (!emailRegex.test(email)) {
        toast.error(`Please enter a valid email address: ${email}`);
        setLoading(false);
        return; // Stop execution if an invalid email is found
      }
    }
    try {
      await userSendMail(to, subject,cc,bcc, body, from, userId, attachments);
      toast.success("Email sent successfully!");
      setTo("");
      setSubject("");
      setBody("");
      setCc("");
      setBcc("");
      setLoading(false);
      setAttachments([]);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to send email");
      console.error("Error sending email:", error);
    }
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { header: "3" }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ size: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "list",
    "bullet",
    "indent",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "align",
    "link",
  ];

  const handleFileChange = (event) => {
    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    const files = Array.from(event.target.files);

    const validFiles = files.filter((file) => file.size <= MAX_FILE_SIZE);

    const invalidFiles = files.filter((file) => file.size > MAX_FILE_SIZE);

    if (invalidFiles.length > 0) {
      toast.error(`Some files exceed the 5MB limit and were not added.`);
      setAttachments([]);
    }

    if (validFiles.length > 0) {
      setAttachments((prevAttachments) => [...prevAttachments, ...validFiles]);
    }
  };

  const handleRemoveFile = (fileName) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((file) => file.name !== fileName)
    );
  };

  // const handleDraftEmail = async () => {
  //   console.log(to, subject, cc, bcc, body, "handleDraftEmail>>>>>>>");

  //   const resp = await draftEmail(
  //     to || "",
  //     subject || "",
  //     cc || "",
  //     bcc || "",
  //     body || "",
  //      "null"
  //   );

  //   if (resp.status === 200) {
  //     toast.success("Email drafted");
  //     setTo("");
  //     setSubject("");
  //     setBody("");
  //     setCc("");
  //     setBcc("");
  //     setLoading(false);
  //     setAttachments([]);
  //   } else {
  //     toast.error("Something went wrong");
  //   }
  // };
  const fakeDraftEmail = async () => {
    console.log("fakeee")
  };
  const handleDraftEmail = async () => {
    if (isDrafting) {
      // If draft saving is already in progress, do nothing (fake saving draft)
      fakeDraftEmail();
      return;
    }

    setIsDrafting(true); // Set drafting to true immediately

    try {
      const resp = await draftEmail(to || "", subject || "", cc || "", bcc || "", body || "", "null");

      // Check if draft creation was successful
      if (resp.status === 200) {
        toast.success("Email drafted successfully!");
        setTo("");
        setSubject("");
        setBody("");
        setCc("");
        setBcc("");
        setAttachments([]);
      } else {
        toast.error("Something went wrong while drafting the email.");
      }
    } catch (error) {
      toast.error("Failed to save the draft.");
    } finally {
      // Ensure isDrafting is set to false once the request finishes, whether success or failure
      setIsDrafting(false);
    }
  };
 
  return (
    <div className="compose">
      <div className="mx-auto p-[0px]">
        <div className="compose-main xl:w-3/4 w-full mx-auto">
          <div className="bg-[#fff] flex justify-between items-center mb-2 py-3">
            <h3 className="text-[#000] text-base font-semibold mb-0">
              &nbsp;&nbsp; New Message
            </h3>
            <button
              onClick={() => {
                if (to || subject) {
                  handleDraftEmail();
                }
              }}
              className="close-btn ml-auto bg-transparent border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              disabled={isDrafting}
            >
              <span className="bg-transparent text-[#333] h-6 w-6 text-xl block outline-none focus:outline-none">
                <IoMdClose />
              </span>
            </button>
          </div>

          <div className="py-3 px-[15px] bg-[#fff] rounded-md shadow">
            <div className="d-flex border-b border-[#eee] pb-3 mb-3 text-sm items-center">
              <input
                type="text"
                placeholder="To"
                value={to}
                onChange={(e) => setTo(e.target.value)}
                className="w-full bg-[transparent] text-black outline-none"
              />
                 <button
                onClick={() => setShowCc(!showCc)}
                className="ml-3 text-gray-900 text-sm mt-2"
              >
                {showCc ? "" : "CC"}
              </button>
              
              &nbsp; &nbsp;
              <button
                onClick={() => setShowBcc(!showBcc)}
                className="ml-3 text-gray-900  text-sm mt-2"
              >
                {showBcc ? "" : "BCC"}
              </button>
            
            </div>
            {showCc && (
              <div className="border-b border-[#eee] pb-3 mb-3 text-sm">
                <input
                  type="text"
                  placeholder="CC"
                  value={cc}
                  onChange={(e) => setCc(e.target.value)}
                  className="w-full bg-[transparent] text-black outline-none"
                />
              </div>
            )}
            {showBcc && (
              <div className="border-b border-[#eee] pb-3 mb-3 text-sm">
                <input
                  type="text"
                  placeholder="BCC"
                  value={bcc}
                  onChange={(e) => setBcc(e.target.value)}
                  className="w-full bg-[transparent] text-black outline-none"
                />
              </div>
            )}
            <div className="border-b border-[#eee] pb-3 mb-3 text-sm">
              <input
                type="text"
                placeholder="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="w-full bg-[transparent] text-black outline-none"
              />
            </div>
            <div className="w-full mb-3">
              <ReactQuill
                value={body}
                onChange={setBody}
                modules={modules}
                formats={formats}
                bounds={".app"}
                placeholder="Write something..."
                // onImageUpload={handleImageUpload}
                // style={{ height: '250px' }}
              />
            </div>

            {/* Attachments Section */}
            {attachments.length > 0 && (
              <div>
                {attachments.map((attachment, i) => {
                  return (
                    <div
                      className="flex items-center bg-[#f5f5f5] w-fit px-2 mt-2"
                      key={i}
                    >
                      <p className="mb-0">{attachment.name}</p>
                      <X
                        className="w-[16px] h-[16px] font-bold mt-1 ml-3 cursor-pointer rounded-sm"
                        onClick={() => handleRemoveFile(attachment.name)}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="bg-[#fff] rounded-md shadow py-2 px-[15px] mt-2 flex items-center justify-between">
            <div className="flex items-center gap-3">
              <button
                onClick={() => fileInputRef.current.click()}
                className="flex items-center space-x-2"
              >
                <IoAttach className="w-[20px] h-[20px] text-black" />
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  multiple
                  onChange={handleFileChange}
                />
              </button>
            </div>
            <div>
              <button
                className="flex items-center bg-[#063c66] text-white py-2 px-4 rounded-md hover:bg-[#0e4d7f]"
                onClick={handleSendEmail}
                disabled={loading}
              >
                {loading ? "Sending..." : "Send"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComposeEmail;
