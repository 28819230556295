import React, { useEffect, useState } from "react";
import { MdOutlineMail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  getDomainsByUser,
  getUserCustomEmails
} from "../../Api/ExportApi";

const AlertEmailModal = () => {
  const [showModal, setShowModal] = useState(true);
  const [userDomains, setUserDomains] = useState([]);
  const [userCustomEmails, setUserCustomEmails] = useState([]);
  const navigate = useNavigate();

  const handleCreateEmailAddress = () => {
    navigate("/custom-email");
    setShowModal(false);
  };

  const getDomains = async () => {
    try {
      const resp = await getDomainsByUser();
      if (resp.status === 200) {
        setUserDomains(resp?.data);
        if (resp?.data?.length === 0) {
          setShowModal(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCustomEmails = async () => {
    try {
      const resp = await getUserCustomEmails();
      setUserCustomEmails(resp?.data);
    } catch (err) {
      console.error("Error fetching user data:", err.message);
    }
  };

  useEffect(() => {
    getDomains();
    getAllCustomEmails();
  }, []);

  return (
    <>
      {userDomains?.length > 0 && !userCustomEmails?.length === 0 && (
        <div className="flex items-center justify-center h-screen z-[10000] absolute top-0 left-0">
          {showModal && (
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
              onClick={() => setShowModal(false)}
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
          )}

          {showModal && (
           <div
           className="fixed z-[10000] inset-0 overflow-y-auto"
           aria-modal="true"
           role="dialog"
           >
           <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
             <div className="w-full inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
               <div className="bg-white px-6 pt-6 pb-4 sm:p-6 sm:pb-4">
                 <div className="sm:flex sm:items-start">
                   <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                     {/* <MdOutlineMail className="h-6 w-6 text-blue-600" /> */}
                   </div>
                   <div className="w-full sm:mt-0 sm:ml-4">
                     <h3
                       className="text-lg leading-6 font-medium text-gray-900"
                       id="modal-headline"
                     >
                       No Custom Email Addresses
                     </h3>
                     <div className="mt-2">
                       <p className="text-sm text-gray-500">
                         You currently don’t have any custom email addresses
                         linked to your account. Please create one.
                       </p>
                     </div>
                   </div>
                 </div>
               </div>
               <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                 <button
                   // onClick={handleCreateEmailAddress}
                   type="button"
                   className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                 >
                   Create Email Address
                 </button>
                 <button
                   onClick={() => setShowModal(false)}
                   type="button"
                   className=" w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                 >
                   Cancel
                 </button>
               </div>
             </div>
           </div>
           </div>
          )}
        </div>
      )}
    </>
  );
};

export default AlertEmailModal;
