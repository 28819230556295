import React, { useEffect, useState } from "react";
import { CgLink } from "react-icons/cg";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoMdClose, IoMdRefresh } from "react-icons/io";
import { IoTimeOutline, IoTrashOutline } from "react-icons/io5";
import {
  MdArchive,
  MdFullscreen,
  MdOutlineChevronLeft,
  MdOutlineChevronRight,
  MdOutlineStarBorderPurple500,
} from "react-icons/md";
// import "../Inbox/style.css";
import { Link, useNavigate } from "react-router-dom";
import useCheckboxHandler from "../../Components/hooks/useCheckboxHandler";
import {
  discardMultipleDrafts,
  getDraftEmails,
  moveAllEmailToTrash,
  moveToTrash,
  toggleArchiveStatus,
  toggleFavoriteStatus,
} from "../../Api/ExportApi";
import ToastHandle from "../../Components/helpers/ToastMessage";
import { jwtDecode } from "jwt-decode";
import { MdOutlineArchive } from "react-icons/md";
import MarkAllRead from "../../Components/Ui/MarkAllRead";
import { formatDate } from "../../Components/helpers/FormatDate";
import ComposeEmailModel from "../../Pages/ComposeEmailModel";
import { FiMinus } from "react-icons/fi";
import { toast } from "react-toastify";
import moment from "moment";

const Drafts = () => {
  const [draftEmails, setDraftEmails] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [isLoading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [minimizeMode, setMinimizeMode] = useState(false);
  const [expandModeParent, setExpandModeParent] = useState(false);

  const [values, setValues] = useState({
    to: "",
    cc: "",
    bcc: "",
    subject: "",
    body: "",
    id:"",
  });

  const {
    selectAllCheckbox,
    selectedItems,
    setSelectAllCheckbox,
    setSelectedItems,
    handleCheckboxClick,
  } = useCheckboxHandler(draftEmails);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const userId = decodedToken.userId;

          // const resp = await userDatabyid(userId);
          setUserId(userId);
        } catch (err) {
          console.error("Error fetching user data:", err.message);
        }
      } else {
        console.log("No token found");
      }
    };

    fetchUserData();
  }, []);

  const fetchDraftEmails = async (page) => {
    setLoading(true);
    try {
      const resp = await getDraftEmails(page);

      setDraftEmails(resp.data.emails || []);
      setTotalItems(resp?.data?.total || 0);
      setItemPerPage(resp?.data?.limit || 0);
      setSelectedItems([]);
      setSelectAllCheckbox(false);
    } catch (err) {
      // toast.error("Failed to fetch emails");
      console.error("Error fetching incoming emails:", err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDraftEmails(currentPage);
  }, [currentPage]);

  const handleToggleFavoriteStatus = async (emailId) => {
    try {
      await toggleFavoriteStatus(emailId);
      // ToastHandle("Email favorite status updated", "success");
      toast.success("Email favorite status updated")
      fetchDraftEmails(currentPage);
    } catch (error) {
      // ToastHandle("Failed to update favorite status", "danger");
      toast.error("Failed to update favorite status");
      console.error("Error updating favorite status:", error);
    }
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleRefresh = () => {
    fetchDraftEmails(currentPage);
  };

  const handleMoveToTrash = async (emailId, redirect) => {
    try {
      await moveToTrash(emailId);
      // ToastHandle("Email moved to trash", "success");
      toast.success("Email moved to trash");
      if (redirect) {
        navigate("/trash");
      } else {
        fetchDraftEmails(currentPage);
      }
    } catch (error) {
      //ToastHandle("Failed to move email to trash", "danger");
     toast.error("Failed to move email to trash");

      console.error("Error moving email to trash:", error);
    }
  };

  const handleToggleArchiveStatus = async (emailId) => {
    try {
      const resp = await toggleArchiveStatus(emailId);
      if (
        resp.message === "Email added to archive" ||
        "Email removed from archive"
      ) {
        fetchDraftEmails(currentPage);
        // ToastHandle("Email archive status updated");
        toast.success("Email archive status updated");
      } else {
        // ToastHandle("Failed to update archive status", "danger");
        toast.error("Failed to update archive status");
      }
    } catch (error) {
      // ToastHandle("Failed to update archive status", "danger");
      toast.error("Failed to update archive status");
      console.error("Error updating archive status:", error);
    }
  };

  const handleDeleteAll = async () => {
    try {
      await moveAllEmailToTrash([...selectedItems]);
      if (selectedItems.length === draftEmails.length) {
        if (currentPage > 1) {
          fetchDraftEmails(currentPage - 1);
        } else {
          fetchDraftEmails(currentPage);
        }
      }
    } catch (error) {
      // ToastHandle("Failed to delete email.", "danger");
      toast.error("Failed to delete email.");
      console.error("Error deleting email:", error);
    }
  };

  const handleDiscardDrafts = async () => {
    try {
      await discardMultipleDrafts([...selectedItems]);

      if (selectedItems.length === draftEmails.length) {
        if (currentPage > 1) {
          fetchDraftEmails(currentPage - 1);
        } else {
          fetchDraftEmails(currentPage);
        }
      } else {
        fetchDraftEmails(currentPage);
      }
    } catch (error) {
      // ToastHandle("Failed to discrad drafts.", "danger");
      toast.error("Failed to discrad drafts.");
      console.error("Error discrad drafts:", error);
    }
  };

  const handleMinimizeMode = (value) => {
    setMinimizeMode(value);
  };

  const handleExpandModeParent = (value) => {
    setExpandModeParent(value);
  };

  return (
    <>
      <div className="inbox">
        <div className="bg-white inbox-header flex flex-wrap items-center justify-between px-3 pb-2">
          <div className="flex items-center gap-3 relative">
            <span className="block mt-1">
              {/* <MdOutlineCheckBoxOutlineBlank className='text-xl' /> */}
              <input
                checked={selectAllCheckbox && draftEmails?.length > 0}
                type="checkbox"
                className="cursor-pointer"
                onChange={(e) => handleCheckboxClick(e, "toggle")}
              />
            </span>
            <div className="dropdown inline-block relative">
              <button className=" text-gray-700 font-semibold hover:bg-gray-200 text-gray-500 py-2 px-1 rounded inline-flex items-center">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                </svg>
              </button>
              <ul className="dropdown-menu absolute hidden text-gray-700 pt-1">
                <li
                  className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                  onClick={(e) => handleCheckboxClick(e, "all")}
                >
                  All
                </li>
                <li
                  className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                  onClick={(e) => handleCheckboxClick(e, "none")}
                >
                  None
                </li>
                <li
                  className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                  onClick={(e) => handleCheckboxClick(e, "read", null, userId)}
                >
                  Read
                </li>
                <li
                  className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                  onClick={(e) =>
                    handleCheckboxClick(e, "unread", null, userId)
                  }
                >
                  Unread
                </li>
                <li
                  className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                  onClick={(e) =>
                    handleCheckboxClick(e, "starred", null, userId)
                  }
                >
                  Starred
                </li>
                <li
                  className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                  onClick={(e) =>
                    handleCheckboxClick(e, "unstarred", null, userId)
                  }
                >
                  Unstarred
                </li>
              </ul>
            </div>
            <button onClick={handleRefresh}>
              <IoMdRefresh className="text-2xl  hover:bg-gray-200 text-gray-500 rounded" />
            </button>
            <MarkAllRead
              emails={draftEmails}
              selectedItems={selectedItems}
              userId={userId}
              currentPage={currentPage}
              refetchEmails={fetchDraftEmails}
              selectAllCheckbox={selectAllCheckbox}
            />

            {selectedItems?.length > 1 && (
              <button
                className="font-[500] text-gray-400 hover:bg-gray-200 p-1 rounded-sm"
                onClick={handleDeleteAll}
              >
                <IoTrashOutline
                  size={24}
                  className="text-xl text-gray-400 hidden md:block"
                />
              </button>
            )}

            {selectedItems?.length > 1 && (
              <button
                className="font-[500] text-gray-400 hover:bg-gray-200 p-1 rounded-sm"
                onClick={handleDiscardDrafts}
              >
                Discard drafts
              </button>
            )}
          </div>
          <div className="flex items-center gap-3">
            <p className="text-gray-500 font-normal text-base hover:bg-gray-200 p-2 rounded">
              {/* <Link to="">1-50 of 51-100</Link> */}
              {startItem}-{endItem} of {totalItems}
            </p>
            <div className="flex items-center">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <MdOutlineChevronLeft className="text-gray-800 text-xl hover:bg-gray-200 rounded" />
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <MdOutlineChevronRight className="text-gray-800 text-xl hover:bg-gray-200 rounded" />
              </button>
            </div>
          </div>
        </div>
   
        <div className="mb-2"></div>
        


        <div className="bg-[#fff] rounded-md shadow">
          <div className="w-full">
      
            {draftEmails?.length > 0 ? (
              <>
                {draftEmails?.map((email) => (
                  <div
                    className={`cursor-pointer bg-white item flex item-center gap-3 border-b  p-3 hover:shadow-lg ${
                      email?.readBy && email?.readBy?.includes(userId)
                        ? "!bg-[#e4f3ff] hover:!shadow-none"
                        : ""
                    }`}
                    key={email?._id}
                  >
                    <div className="flex items-center  gap-2">
                      <div className="gap-2 flex items-center">
                        <div className="inline-flex items-center">
                          <label
                            className="relative flex items-center rounded-full cursor-pointer"
                            htmlFor="check"
                          >
                            <input
                              type="checkbox"
                              className=" before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded border-2 border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-transparent checked:before:bg-transparent hover:before:opacity-10"
                              id="check"
                              checked={
                                selectAllCheckbox ||
                                selectedItems.includes(email?._id)
                              }
                              name="checkbox"
                              onChange={(e) =>
                                handleCheckboxClick(e, "single", email?._id)
                              }
                            />
                            <span className="absolute text-gray-600 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-3 w-3"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                stroke="currentColor"
                                stroke-width="1"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                            </span>
                          </label>
                        </div>
                       
                      </div>
                    </div>

                    <div className="flex items-center justify-between w-full">
                      <div
                        className="flex items-center w-full"
                        onClick={() => {
                          setValues({
                            id: email?._id,
                            to: email?.to,
                            cc: email?.cc,
                            bcc: email?.bcc,
                            subject: email?.subject,
                            body: email?.body,
                          });
                          setShowModal(true);
                        }}
                        // onClick={() =>
                        //   navigate(`/inbox-single/${email?._id}`)
                        // }
                      >
                        <div className="">
                          <p className="font-medium text-[14px] text-gray-700">
                            {email.subject ? email.subject : "No Subject"}
                          </p>
                          <p
                            className="font-normal text-[12px] text-gray-600"
                            dangerouslySetInnerHTML={{
                              __html: email?.body?.slice(0, 40),
                            }}
                          />
                        </div>
                      </div>

                      <div className="gap-2 flex items-center relative">
                        <div className="absolute top-0 md:right-24 right-16 flex items-center gap-2">
                          <button
                            onClick={() =>
                              handleToggleArchiveStatus(email?._id)
                            }
                          >
                            {email?.archivedBy &&
                            email?.archivedBy?.includes(userId) ? (
                              <MdArchive size={20} />
                            ) : (
                              <MdOutlineArchive className="text-xl text-gray-400 hidden md:block" />
                            )}
                          </button>
                          <button
                            onClick={() => handleMoveToTrash(email?._id, false)}
                          >
                            <IoTrashOutline className="text-xl text-gray-400 hidden md:block" />
                          </button>
                          <button
                            onClick={(event) => {
                              event.stopPropagation();
                              handleToggleFavoriteStatus(email._id);
                            }}
                          >
                            <MdOutlineStarBorderPurple500
                              className={`text-xl ${
                                email?.starredBy &&
                                email?.starredBy?.includes(userId)
                                  ? "text-yellow-500"
                                  : "text-gray-400"
                              }   md:hidden block `}
                            />
                          </button>
                          &nbsp;
                        </div>
                        <button className="flex items-center whitespace-nowrap gap-2">
                 
                          <span>   {moment(email?.createdAt).format(
                                  "MMMM D"
                                )}</span>
                        
                        
                        </button>
                        <button
                          onClick={(event) => {
                            event.stopPropagation();
                            handleToggleFavoriteStatus(email._id);
                          }}
                        >
                          <MdOutlineStarBorderPurple500
                            className={`text-xl ${
                              email?.starredBy &&
                              email?.starredBy?.includes(userId)
                                ? "text-yellow-500"
                                : "text-gray-400"
                            }   hidden md:block `}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}{" "}
              </>
            ) : (
              <div className="w-fill flex justify-center items-center h-[50vh]">
                <div colSpan="3" className="text-center py-4">
                  No emails found
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {showModal && !minimizeMode && (
        <ComposeEmailModel
          showModal={showModal}
          handleMinimizeMode={handleMinimizeMode}
          closeModel={() => setShowModal(false)}
          expandModeParent={expandModeParent}
          handleExpandModeParent={handleExpandModeParent}
          draftValues={values}
        />
      )}

      {minimizeMode && showModal && (
        <div
          className="w-[240px] bg-white z-50 fixed bottom-0 right-10 rounded-tl-lg rounded-tr-lg"
          style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
        >
          <div className="header flex items-start justify-between rounded py-2 px-3 bg-[#eee] ">
            <h5
              className="text-[#333] font-semibold text-[14px]"
              onClick={() => {
                setMinimizeMode(false);
              }}
            >
              New Message
            </h5>
            <div className="flex gap-0 items-center">
              <button className="ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
                <span className="bg-transparent  text-[#333] h-6 w-6 text-xl block outline-none focus:outline-none">
                  <FiMinus
                    onClick={() => {
                      handleMinimizeMode(!minimizeMode);
                    }}
                  />
                </span>
              </button>
              <button className="ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
                <span className="bg-transparent text-[#333] h-6 w-6 text-xl block outline-none focus:outline-none">
                  <MdFullscreen
                    onClick={() => {
                      setMinimizeMode(false);
                      setExpandModeParent(true);
                    }}
                  />
                </span>
              </button>
              <button
                onClick={() => {
                  setShowModal(false);
                  setMinimizeMode(false);
                }}
                className="close-btn ml-auto bg-transparent border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              >
                <span className="bg-transparent text-[#333] h-6 w-6 text-xl block outline-none focus:outline-none">
                  <IoMdClose />
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Drafts;
