import BaseApi from "./BaseApi";

const userLogin = (email, password) => {
  return BaseApi.post("/users/login", { email, password });
};

const userRegister = (userData) => {
  return BaseApi.post("/users/register", userData);
};

const userDatabyid = (userId) => {
  return BaseApi.get(`/users/${userId}`);
};

const getIncomingEmails = (inboxId) => {
  return BaseApi.get(`/mails/emails/${inboxId}`);
};

const getIncomingEmailbyId = (emailId) => {
  return BaseApi.get(`/mails/incoming/${emailId}`);
};

const getEmailsByUserId = (page) => {
  return BaseApi.get(`/mails/user?page=${page}`);
};

const userProfileUpdate = (formData) => {
  return BaseApi.patch("/users/update-profile", formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Required for file uploads
    },
  });
};

const toggleFavoriteStatus = (emailId) => {
  return BaseApi.patch(`/mails/${emailId}/favorite`);
};

const getuserIncomingMails = (page) => {
  return BaseApi.get(`/mails/emails/getuseremails?page=${page}`);
};


const getStarredMails = (page) => {
  return BaseApi.post(`/mails/emails/getstarredemails?page=${page}`);
};

const getArchiveEmails = (page) => {
  return BaseApi.get(`/mails/archive/emails?page=${page}`);
};

const emailRead = (id) => {
  return BaseApi.post(`/mails/emails/emailread`, { id });
};

const markAllRead = (emailIds) => {
  return BaseApi.put(`/mails/emails/mark-all-read`,  { emailIdsArr: emailIds });
};

const replyToEmail = (id, body, attachments, toEmails, ccEmails, bccEmails) => {
  const formData = new FormData();
  formData.append('id', id);
  formData.append('body', body);
  formData.append('toEmails', toEmails);
  formData.append('ccEmails', ccEmails);
  formData.append('bccEmails', bccEmails);

  console.log(toEmails, ccEmails, bccEmails); 
  

  if (attachments && attachments.length > 0) {
    Array.from(attachments).forEach((file) => {
      formData.append('attachments', file);
    });
  }

  return BaseApi.post(`/mails/email/reply/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const getEmailReplies = (id) => {
  return BaseApi.post(`/mails/email/replies/${id}`);
};

const userSendMail = (to, subject,cc,bcc, body, from, userId, attachments) => {
  const formData = new FormData();
  formData.append('to', to);
  formData.append('subject', subject);
  formData.append('cc', cc);
  formData.append('bcc', bcc);
  formData.append('body', body);
  formData.append('from', from);
  formData.append('userId', userId);

  if (attachments && attachments.length > 0) {
    Array.from(attachments).forEach((file) => {
      formData.append('attachments', file);
    });
  }

  return BaseApi.post('/mails/send-mail', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const uploadphoto = () => {
  return BaseApi.post("/mails/upload-photo", {});
};

const moveToTrash = (emailId) => {
  return BaseApi.patch(`mails/${emailId}/trash`);
};

const emailUnread = (emailId) => {
  return BaseApi.put(`mails/email/unread/${emailId}`);
};

const moveAllEmailToTrash = (emailIds) => {
  return BaseApi.put(`mails/trashed-all-emails`, { emailIdsArr: emailIds });
};

const getTrashEmails = (page) => {
  return BaseApi.get(`mails/trashed?page=${page}`);
};

const permanentDelete = (emailId) => {
  return BaseApi.delete(`mails/delete-email/${emailId}`);
};

const permanentDeleteAllEmails = (emailIds) => {
  return BaseApi.post(`mails/delete-all-emails`, { emailIdsArr: emailIds });
};

const emptyTrash = (emailIds) => {
  return BaseApi.post(`mails/empty-trash`, { emailIdsArr: emailIds });
};


const multiEmailsStarRemove = (emailIds) => {
  return BaseApi.put(`mails/emails/remove-starred`, { emailIdsArr: emailIds });
};

const multiEmailsStarred = (emailIds) => {
  return BaseApi.put(`mails/emails/starred-emails`, { emailIdsArr: emailIds });
};

const multiEmailUnread = (emailIds) => {
  return BaseApi.put(`mails/emails/multi-unread`, { emailIdsArr: emailIds });
};

const multiEmailRead = (emailIds) => {
  return BaseApi.put(`mails/emails/multi-read`, { emailIdsArr: emailIds });
};

const getSearchEmails = (params) => {
  const queryString = new URLSearchParams(params).toString();

  return BaseApi.get(`/mails/search?${queryString}`);
};

const toggleArchiveStatus = (emailId) => {
  return BaseApi.patch(`/mails/archive/${emailId}`);
};

const createEntire = (applicationId, secret) => {
  return BaseApi.post(`/domain/createentire`, { applicationId, secret });
};

const forgotPassword = (email) => {
  return BaseApi.post(`/forgot-password`, {email});
};

const resetPassword = (token, password) => {
  return BaseApi.post(`/reset-password/${token}`, {password});
};


const verifyToken = (token) => {
  return BaseApi.get(`/verify-token/${token}`);
};

const deleteDomain = (domain) => {
  return BaseApi.post(`/domain/delete-domain`, {domain: domain});
};

const domainStatusUpdate = (domain) => {
  return BaseApi.post(`/domain/status-update`, {domain: domain});
};
const domainStatusVerify = (domain) => {
  return BaseApi.post(`/domain/domain-status-verify`, {domain: domain});
};

// const getArchiveEmails = (page) => {
//   return BaseApi.get(`/mails/archive/emails?page=${page}`);
// };

const getDomainsByUser = () => {
  return BaseApi.get(`/domain/getDomainsByUser`);
};
const verifyUserDomain = (domain) => {
  return BaseApi.post(`/domain/verify-domain`, {domain: domain});
};



const createSmtpUser = (email, domain, password, firstName, lastName) => {
  return BaseApi.post(`/domain/create-smtp-user`, {email, domain, password, firstName, lastName});
};

const deleteSmtpUser = (email) => {
  return BaseApi.post(`/domain/delete-smtp-user`, {email});
};
// const getDomainsByUser = () => {
//   return BaseApi.get(`/domain/getDomainsByUser`);
// };
const getUserCustomEmails = () => {
  return BaseApi.get(`/domain/get-user-custom-emails`);
};

const getVerifiedDomainsByUser = () => {
  return BaseApi.get(`/domain/get-verified-domains-by-user`);
};

const setDefaultEmail = (email) => {
  return BaseApi.patch(`/users/set-default-email`, {email: email});
};

const getDraftEmails = (page) => {
  return BaseApi.get(`/mails/draft-mails?page=${page}`);
};

const discardMultipleDrafts = (emailIds) => {
  return BaseApi.post(`mails/discard-drafts`, { emailIdsArr: emailIds });
};

const draftEmail = (to, subject, cc, bcc, body, id) => {
  const formData = new FormData();
  formData.append('to', to);
  formData.append('subject', subject);
  formData.append('cc', cc);
  formData.append('bcc', bcc);
  formData.append('body', body);
  formData.append('id', id);

  return BaseApi.post(`/mails/draft-mail`, formData);
};

const cancelSubscription = (subscriptionId) => {
  return BaseApi.post(`/cancel-subscription`, {subscriptionId: subscriptionId});
};

const resumeSubscription = (subscriptionId) => {
  return BaseApi.post(`/resume-subscription`, {subscriptionId: subscriptionId});
};

const getPaymentsdetails = () => {
  return BaseApi.get(`/get-all-payments`);
};
 

export {
  getPaymentsdetails,
  resumeSubscription,
  cancelSubscription,
  draftEmail,
  discardMultipleDrafts,
  getDraftEmails,
  setDefaultEmail,
  getVerifiedDomainsByUser,
  getUserCustomEmails,
  // getDomainsByUser,
  deleteSmtpUser,
  createSmtpUser,
  verifyUserDomain,
  getDomainsByUser,
  // getArchiveEmails,
  domainStatusVerify,
  domainStatusUpdate,
  deleteDomain,
  userLogin,
  userRegister,
  userDatabyid,
  getIncomingEmails,
  getIncomingEmailbyId,
  getEmailsByUserId,
  userProfileUpdate,
  toggleFavoriteStatus,
  getuserIncomingMails,
  userSendMail,
  uploadphoto,
  moveToTrash,
  moveAllEmailToTrash,
  permanentDelete,
  permanentDeleteAllEmails,
  getTrashEmails,
  getSearchEmails,
  toggleArchiveStatus,
  createEntire,
  getStarredMails,
  emailRead,
  replyToEmail,
  getEmailReplies,
  getArchiveEmails,
  emailUnread,
  markAllRead,
  multiEmailsStarRemove,
  multiEmailsStarred,
  multiEmailUnread,
  multiEmailRead,
  emptyTrash,
  forgotPassword,
  verifyToken,
  resetPassword
};
