import {
  ChevronLeft,
  ChevronRight,
  Mail,
  Reply,
  Trash2,
  X,
} from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlinePrinter } from "react-icons/ai";
import { IoMdArrowBack } from "react-icons/io";
import { IoAttach, IoTrashOutline } from "react-icons/io5";
import { MdDownload, MdMoreVert, MdOutlineArchive } from "react-icons/md";
import { RiInboxArchiveFill } from "react-icons/ri";
import { RxOpenInNewWindow } from "react-icons/rx";
import Profile2 from "../../images/profile1.png";
import { useParams } from "react-router-dom";
import { FaFile, FaFileImage, FaFilePdf, FaRegStar } from "react-icons/fa";
import { GoReply } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import {
  getIncomingEmailbyId,
  toggleFavoriteStatus,
  moveToTrash,
  toggleArchiveStatus,
  emailRead,
  replyToEmail,
  getEmailReplies,
  emailUnread,
  userDatabyid,
} from "../../Api/ExportApi";
import moment from "moment";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { jwtDecode } from "jwt-decode";
import ReactQuill from "react-quill";
import { saveAs } from "file-saver";

const InboxSingle = () => {
  const { id } = useParams();
  const [email, setEmail] = useState();
  const [favoriteEmails, setFavoriteEmails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailToTrash, setEmailToTrash] = useState(null);
  const [handleShowReply, setHandleShowReply] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [replies, setReplies] = useState([]);
  const [body, setBody] = useState("");
  const dropdownRefs = useRef({});
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [openParentDropdown, setOpenParentDropdown] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [toEmails, setToEmails] = useState(email?.to || []);
  const [ccEmails, setCcEmails] = useState(email?.cc || []);
  const [bccEmails, setBccEmails] = useState(email?.bcc || []);
  const [userData, setUserData] = useState();

  const fileInputRef = useRef(null);

  const navigate = useNavigate();

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { header: "3" }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ size: [] }],
      ["image", "video"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "list",
    "bullet",
    "indent",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "align",
    "image",
    "video",
  ];

  const handleImageUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(
        "http://localhost:3000/api/mails/upload-photo",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Image upload failed");
      }

      const data = await response.json();
      return data.url; // Adjust according to your backend response
    } catch (error) {
      console.error("Image upload failed:", error);
      return null;
    }
  };

  useEffect(() => {
    const url = window.location.href;

    const queryString = url.split("?")[1];
    if (queryString && queryString.includes("print")) {
      setTimeout(() => {
        handlePrint();
      }, 1000);
    }

    // const fetchUserData = async () => {
    //   const token = localStorage.getItem("token");
    //   if (token) {
    //     try {
    //       const decodedToken = jwtDecode(token);
    //       const id = decodedToken.userId;
    //       setUserId(id);
    //     } catch (err) {
    //       console.error("Error fetching user data:", err.message);
    //     }
    //   } else {
    //     console.log("No token found");
    //   }
    // };
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const id = decodedToken.userId;
          const resp = await userDatabyid(id);
          setUserData(resp?.data?.user);
          setUserId(id);
        } catch (err) {
          console.error("Error fetching user data:", err.message);
        }
      } else {
        console.log("No token found");
      }
    };

    fetchUserData();

    const handleClickOutside = (event) => {
      if (
        dropdownRefs.current &&
        !Object.values(dropdownRefs.current).some(
          (ref) => ref && ref.contains(event.target)
        )
      ) {
        setOpenDropdownId(null);

        setOpenParentDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (userId && id) {
      fetchEmail();
      //   changeEmailReadStatus(id);
      fetchEmailReplies();
    }
  }, [userId, id]);

  const fetchEmail = async () => {
    try {
      const response = await getIncomingEmailbyId(id);
      setEmail(response.data);
      setToEmails(response.data?.to);
      setCcEmails(response.data?.cc);
      setBccEmails(response.data?.bcc);

      if (!response.data?.readBy?.includes(userId)) {
        changeEmailReadStatus(id);
      }
    } catch (err) {
      console.error("Error fetching email:", err);
    }
  };

  const fetchEmailReplies = async () => {
    try {
      const response = await getEmailReplies(id);
      console.log(response, "fetchEmailReplies>>>1");
      setReplies(response.data);
    } catch (err) {
      console.error("Error fetching email replies:", err);
    }
  };

  const changeEmailReadStatus = async (id) => {
    try {
      await emailRead(id);
    } catch (err) {
      console.error("Error email read status:", err);
    }
  };

  const handleToggleFavoriteStatus = async (emailId) => {
    try {
      await toggleFavoriteStatus(emailId);
      setFavoriteEmails((prevFavorites) => ({
        ...prevFavorites,
        [emailId]: !prevFavorites[emailId],
      }));
      fetchEmail();
      fetchEmailReplies();
      toast.success("Email favorite status updated");
    } catch (error) {
      toast.error("Failed to update favorite status");
      console.error("Error updating favorite status:", error);
    }
  };

  const handleToggleArchiveStatus = async (emailId) => {
    try {
      // Call the function and get the response
      const resp = await toggleArchiveStatus(emailId);
      // Assuming `result` includes a message or updated data
      if (
        resp.message === "Email added to archive" ||
        "Email removed from archive"
      ) {
        fetchEmail();
        toast.success("Email archive status updated");
      } else {
        toast.error("Failed to update archive status");
      }
    } catch (error) {
      toast.error("Failed to update archive status");
      console.error("Error updating archive status:", error);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const handleTrash = (emailId) => {
    setEmailToTrash(emailId);
    setIsModalOpen(true); // Open the modal
  };

  const handleMoveToTrash = async (emailId, redirect) => {
    try {
      await moveToTrash(emailId);
      toast.success("Email moved to trash");
      if (redirect) {
        navigate("/trash");
      } else {
        fetchEmailReplies();
      }
    } catch (error) {
      toast.error("Failed to move email to trash");
      console.error("Error moving email to trash:", error);
    }
  };
  const handleOpenInNewWindow = () => {
    // Define the URL you want to open
    const url = window.location.href;

    // Define window features
    const features =
      "width=600,height=400,left=200,top=100,resizable,scrollbars,status";

    // Open the new window
    window.open(url, "_blank", features);
  };

  const handleEmailReply = async () => {
    if (body.trim() !== "") {
      setLoading(true);

      let updatedToEmails = toEmails.filter(
        (emailAddress) => emailAddress !== userData.email
      );
      let updatedCcEmails = ccEmails.filter(
        (emailAddress) => emailAddress !== userData.email
      );

      if (ccEmails.length > 0 || bccEmails.length > 0) {
        updatedToEmails = [email.from];
        updatedCcEmails = [...new Set([...updatedCcEmails, ...toEmails])];
      }

      if (toEmails.length === 1 && toEmails[0] === userData.email) {
        updatedToEmails = [email.from];
      }

      if (toEmails.length === 1 && userData.email === email.from) {
        updatedToEmails = [...toEmails];
      }

      updatedCcEmails = updatedCcEmails.filter(
        (emailAddress) => !updatedToEmails.includes(emailAddress)
      );

      // const updatedBccEmails = bccEmails.filter(
      //   (emailAddress) => emailAddress !== userData.email
      // );

      const updatedBccEmails = [];

      const response = await replyToEmail(
        id,
        body,
        attachments,
        updatedToEmails,
        updatedCcEmails,
        updatedBccEmails
      );

      if (response.status === 201) {
        setLoading(false);
        setHandleShowReply(false);
        setBody("");
        fetchEmailReplies();
        setAttachments([]);
      } else {
        setLoading(false);
      }
    } else {
      alert("empty");
    }
  };

  const handleDropdownToggle = (id) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };

  const handleReplyInput = (value) => {
    setBody(value);
  };

  const handleMarkUnread = async (id) => {
    try {
      const resp = await emailUnread(id);

      if (resp.status === 200) {
        toast.success("Email marked unread successfully!");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message);
    }
  };

  const handleFileChange = (event) => {
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
    const files = Array.from(event.target.files);

    // Filter files based on the size limit
    const validFiles = files.filter((file) => file.size <= MAX_FILE_SIZE);

    // Check for invalid files
    const invalidFiles = files.filter((file) => file.size > MAX_FILE_SIZE);

    if (invalidFiles.length > 0) {
      toast.error(`Some files exceed the 5MB limit and were not added.`);
      setAttachments([]);
    }

    // Add valid files to attachments
    if (validFiles.length > 0) {
      setAttachments((prevAttachments) => [...prevAttachments, ...validFiles]);
    }
  };

  const handleRemoveFile = (fileName) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((file) => file.name !== fileName)
    );
  };

  const downloadFile = async (url, fileName) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      console.log(blob);

      saveAs(blob, fileName);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      <div className="inbox-single">
        <div className="inbox-single-main">
          <div className="bg-[#fff] rounded-md shadow  py-3 px-[15px] flex flex-wrap gap-2 items-center justify-between">
            <div className="flex items-center gap-3 relative">
              <button onClick={() => navigate(-1)}>
                <IoMdArrowBack className="w-[20px] h-[20px] text-black " />
              </button>

              <button
                onClick={() => handleToggleArchiveStatus(email?._id)}
                disabled={email?.trashBy?.includes(userId)}
              >
                {email?.archivedBy?.includes(userId) ? (
                  <RiInboxArchiveFill
                    className={`w-[20px] h-[20px] text-black`}
                  />
                ) : (
                  <MdOutlineArchive
                    className={`w-[20px] h-[20px] ${
                      email?.trashBy?.includes(userId)
                        ? "text-gray-300"
                        : "text-black"
                    }`}
                  />
                )}
              </button>
              {/* <button>
                <RiSpamLine className="w-[20px] h-[20px] text-black" />
              </button> */}
              <button onClick={() => handleTrash(email?._id)}>
                <IoTrashOutline className="w-[20px] h-[20px] text-black" />
              </button>
              <button
                onClick={() => {
                  openParentDropdown
                    ? setOpenParentDropdown(false)
                    : setOpenParentDropdown(true);
                }}
              >
                <MdMoreVert className="w-[20px] h-[20px] text-black" />
              </button>

              {openParentDropdown && (
                <div
                  ref={(el) => (dropdownRefs.current[email._id] = el)}
                  className="parent-dropdown absolute left-36 z-10 top-6 mt-2 w-fit origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabIndex="-1"
                >
                  <div className="py-1" role="none">
                    <li
                      className="rounded-t flex items-center cursor-pointer text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                      onClick={(event) => {
                        event.stopPropagation();
                        setOpenParentDropdown(false);
                        handleMoveToTrash(email?._id, false);
                      }}
                    >
                      <Trash2 className="w-[18px] h-[18px] text-black mr-1" />{" "}
                      Delete
                    </li>
                    <li
                      className="rounded-t flex items-center cursor-pointer w-[200px] text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                      onClick={(event) => {
                        event.stopPropagation();
                        setOpenParentDropdown(false);
                        setTimeout(() => {
                          handlePrint();
                        }, 500);
                      }}
                    >
                      <AiOutlinePrinter className="w-[20px] h-[20px] text-black mr-1" />{" "}
                      Print
                    </li>
                    <li
                      className="rounded-t flex items-center cursor-pointer w-[220px] text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                      onClick={(event) => {
                        event.stopPropagation();
                        setOpenParentDropdown(false);
                        handleMarkUnread(email?._id);
                      }}
                    >
                      <Mail className="mr-1 w-[20px] h-[20px]" /> Mark unread
                      from here
                    </li>
                  </div>
                </div>
              )}
            </div>
            <div>
              {/* <div className="flex justify-end gap-2 mt-2">
                            <div>
                                <p className="text-base text-black">
                                    1-15 of 15
                                    </p>
                            </div>
                            <div>
                                <button><ChevronLeft className="w-[20px] h-[20px] text-black" /></button>
                                <button><ChevronRight className="w-[20px] h-[20px] text-black" /></button>
                            </div>
                        </div> */}
            </div>
          </div>
          <div className="bg-[#fff] rounded-md shadow lg:p-5 p-3 mt-2">
            <div className="flex items-center flex-wrap justify-between mb-4">
              <div>
                <h3 className="text-base md:text-[20px]  font-semibold text-gray-700">
                  {email?.subject}
                </h3>
              </div>
              <div className="flex items-center gap-2">
                <button onClick={handlePrint}>
                  <AiOutlinePrinter className="w-[20px] h-[20px] text-black" />
                </button>
                <button onClick={handleOpenInNewWindow}>
                  <RxOpenInNewWindow className="w-[20px] h-[20px] text-black" />
                </button>
              </div>
            </div>
            <div className="relative flex flex-wrap gap-2 items-center justify-between mb-4">
              <div className="flex items-center flex-wrap gap-2">
                <div>
                  <img
                    src={Profile2}
                    className="w-[50px] bg-[#eee] rounded-full p-2"
                    alt="profile"
                  />
                </div>
                <div>
                  <div className="flex items-center flex-wrap">
                    <h3 className="text-sm font-semibold text-gray-700 mb-0">
                      {/* {email?.subject} */}
                    </h3>
                    <span className="text-xs text-gray-500 flex items-center">
                      <ChevronLeft className="w-[14px] h-[14px] text-gray-500" />
                      {email?.from}
                      <ChevronRight className="w-[14px] h-[14px] text-gray-500" />
                    </span>
                  </div>
                  {console.log(email, "email>>data")}
                  {/* <span className="text-xs text-gray-500 flex items-center">
                    to: {email?.to}.
                  </span> */}
                  <span className="text-xs text-gray-500 flex items-center">
                    to:{" "}
                    {email?.to && Array.isArray(email.to) ? (
                      email.to.map((emailItem, index) => (
                        <span key={index} className="mr-1">
                          {emailItem}
                          {index < email.to.length - 1 && ", "}{" "}
                          {/* Adds a comma between emails except the last one */}
                        </span>
                      ))
                    ) : (
                      <span>{email?.to}</span> // If it's not an array, display as a single email
                    )}
                  </span>

                  {email?.cc && email.cc.length > 0 ? (
                    <span className="text-xs text-gray-500 flex items-center">
                      cc: {email?.cc}.
                    </span>
                  ) : (
                    ""
                  )}

                  {email?.bcc && email.bcc.length > 0 ? (
                    <span className="text-xs text-gray-500 flex items-center">
                      bcc: {email?.bcc}.
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="flex items-center gap-2">
                <button>
                  <p className="text-sm text-black mb-0">
                    {moment(email?.updatedAt).format("MMMM D")}
                  </p>
                </button>
                <button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleToggleFavoriteStatus(email?._id);
                  }}
                  className={`w-[18px] h-[18px] ${
                    email?.starredBy && email?.starredBy?.includes(userId)
                      ? "text-yellow-500"
                      : "text-gray-500"
                  }`}
                >
                  <FaRegStar />
                </button>
                <button
                  onClick={() => {
                    setHandleShowReply(!handleShowReply);
                    setBody("");
                    if (!handleShowReply === true) {
                      setTimeout(() => {
                        window.scrollTo({
                          top: document.documentElement.scrollHeight,
                          behavior: "smooth",
                        });
                      }, 50);
                    }
                  }}
                >
                  <GoReply className="w-[20px] h-[20px] text-black" />
                </button>
                <button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDropdownToggle(email._id);
                  }}
                >
                  <MdMoreVert className="w-[20px] h-[20px] text-black" />
                </button>
              </div>

              {openDropdownId === email?._id && (
                <div
                  ref={(el) => (dropdownRefs.current[email._id] = el)}
                  className="responsive-dropdown absolute right-0 z-10 top-10 mt-2 w-fit origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabIndex="-1"
                >
                  <div className="py-1" role="none">
                    <li
                      className="rounded-t flex items-center cursor-pointer text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleMoveToTrash(email?._id, false);
                      }}
                    >
                      <Trash2 className="w-[18px] h-[18px] text-black mr-1" />{" "}
                      Delete
                    </li>
                    {/* <li
                      className="rounded-t flex items-center cursor-pointer w-[200px] text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                      onClick={() => {
                        setOpenDropdownId(null)
                        setTimeout(() => {
                          handlePrint();
                        }, 500);
                      }}
                    >
                        <AiOutlinePrinter className="w-[20px] h-[20px] text-black mr-1" />{" "}
                        Print
                    </li> */}
                    <li
                      className="rounded-t flex items-center cursor-pointer w-[220px] text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                      onClick={(event) => {
                        event.stopPropagation();
                        setOpenDropdownId(null);
                        handleMarkUnread(email?._id);
                      }}
                    >
                      <Mail className="mr-1 w-[20px] h-[20px]" /> Mark unread
                      from here
                    </li>
                  </div>
                </div>
              )}
            </div>
            <div className="bg-[#f0f9ff] md:p-5 p-3 md:p-10">
              <div className="mb-4">
                <h3 className="md:text-[20px] text-base font-semibold text-gray-700 mb-2 md:mb-4 break-all">
                  {/* {email?.subject} */}
                </h3>
                <p
                  className="text-sm font-normal text-gray-700 mb-2"
                  dangerouslySetInnerHTML={{ __html: email?.body }}
                />
              </div>

              {email?.attachments?.length > 0 && (
                <div className="flex items-center">
                  {email?.attachments?.map((file, i) => {
                    const fileExtension = file.split(".").pop().toLowerCase();

                    const fileName = `attachment-${i}.${fileExtension}`;

                    if (
                      ["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(
                        fileExtension
                      )
                    ) {
                      return (
                        <div className="mb-4 relative w-fit mr-6" key={i}>
                          <FaFileImage size={80} />

                          <div
                            className="absolute w-full h-full top-[30%] left-[20px]"
                            onClick={() => downloadFile(file, fileName)}
                          >
                            <MdDownload
                              size={40}
                              className="text-[#d4d4d4] cursor-pointer"
                            />
                          </div>
                          {file?.length > 20 ? (
                            <span>
                              {file?.slice(55, 70)}...{fileExtension}
                            </span>
                          ) : (
                            <span>{file}</span>
                          )}
                        </div>
                      );
                    } else if (fileExtension === "pdf") {
                      return (
                        <div className="mb-4 relative w-fit mr-6" key={i}>
                          <FaFilePdf color="red" size={80} />
                          <div
                            className="absolute w-full h-full top-[30%] left-[20px]"
                            onClick={() => downloadFile(file, fileName)}
                          >
                            <MdDownload
                              size={40}
                              className="text-[#d4d4d4] cursor-pointer"
                            />
                          </div>
                          {file?.length > 20 ? (
                            <span>
                              {file?.slice(55, 70)}...{fileExtension}
                            </span>
                          ) : (
                            <span>{file}</span>
                          )}
                        </div>
                      );
                    } else {
                      return (
                        <div className="mb-4 relative w-fit mr-6" key={i}>
                          <FaFile size={80} className="text-[#65b1f3]" />
                          <div
                            className="absolute w-full h-full top-[30%] left-[20px]"
                            onClick={() => downloadFile(file, fileName)}
                          >
                            <MdDownload
                              size={40}
                              className="text-[#d4d4d4] cursor-pointer"
                            />
                          </div>

                          {file?.length > 20 ? (
                            <span>
                              {file?.slice(55, 70)}...{fileExtension}
                            </span>
                          ) : (
                            <span>{file}</span>
                          )}
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            </div>

            {replies?.map((item) => {
              return (
                <div key={item?._id} className="mt-4">
                  <div className="flex flex-wrap gap-2 items-center justify-between mb-4">
                    <div className="flex items-center flex-wrap gap-2">
                      <div>
                        <img
                          src={Profile2}
                          className="w-[50px] bg-[#eee] rounded-full p-2"
                          alt="profile"
                        />
                      </div>
                      <div>
                        <div className="flex items-center flex-wrap">
                          {/* <h3 className="text-sm font-semibold text-gray-700 mb-0 capitalize">
                            {item?.userName}
                          </h3> */}
                          <span className="text-xs text-gray-500 flex items-center">
                            <ChevronLeft className="w-[14px] h-[14px] text-gray-500" />
                            {item?.from}
                            <ChevronRight className="w-[14px] h-[14px] text-gray-500" />
                          </span>
                        </div>

                        {/* <span className="text-xs text-gray-500 flex items-center">
                          to: {item?.to}.
                        </span> */}
                        <span className="text-xs text-gray-500 flex items-center">
                          to:{" "}
                          {item?.to && Array.isArray(item.to) ? (
                            item.to.map((emailItem, index) => (
                              <span key={index} className="mr-1">
                                {emailItem}
                                {index < item.to.length - 1 && ", "}{" "}
                            
                              </span>
                            ))
                          ) : (
                            <span>{item?.to}</span>
                          )}
                        </span>

                        {item?.cc &&
                        item.cc.filter((email) => email.trim() !== "").length >
                          0 ? (
                          <span className="text-xs text-gray-500 flex items-center">
                            cc:{" "}
                            {item.cc
                              .filter((email) => email.trim() !== "")
                              .join(", ")}
                            .
                          </span>
                        ) : (
                          ""
                        )}

                        {item?.bcc &&
                        item.bcc.filter((email) => email.trim() !== "").length >
                          0 ? (
                          <span className="text-xs text-gray-500 flex items-center">
                            bcc:{" "}
                            {item.bcc
                              .filter((email) => email.trim() !== "")
                              .join(", ")}
                            .
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <button>
                        <p className="text-sm text-black mb-0">
                          {moment(item?.updatedAt).format("MMMM D")}
                        </p>
                      </button>
                      <button
                        onClick={(event) => {
                          event.stopPropagation();
                          handleToggleFavoriteStatus(item?._id);
                        }}
                        className={`w-[18px] h-[18px] ${
                          item?.starredBy && item?.starredBy?.includes(userId)
                            ? "text-yellow-500"
                            : "text-gray-500"
                        }`}
                      >
                        <FaRegStar />
                      </button>
                      <button
                        onClick={() => {
                          setHandleShowReply(!handleShowReply);
                          setBody("");
                          if (!handleShowReply === true) {
                            setTimeout(() => {
                              window.scrollTo({
                                top: document.documentElement.scrollHeight,
                                behavior: "smooth",
                              });
                            }, 50);
                          }
                        }}
                      >
                        <GoReply className="w-[20px] h-[20px] text-black" />
                      </button>
                      {/* <button><MdMoreVert className="w-[20px] h-[20px] text-black" /></button> */}
                      <div className="relative inline-block text-left">
                        <div>
                          <button
                            onClick={(event) => {
                              event.stopPropagation(); // Prevent click event from reaching document
                              // setIsMenuOpen((prev) => !prev);
                              // handleToggleFavoriteStatus(item?._id);
                              handleDropdownToggle(item._id);
                            }}
                            className="text-gray-700 font-semibold py-2 rounded inline-flex items-center"
                          >
                            <span className="mr-1">
                              <MdMoreVert className="w-[20px] h-[20px] text-black" />
                            </span>
                          </button>
                        </div>
                        {openDropdownId === item?._id && (
                          <div
                            ref={(el) => (dropdownRefs.current[item._id] = el)}
                            className="reply-dropdown absolute right-0 z-10 mt-2 w-fit origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="menu-button"
                            tabIndex="-1"
                          >
                            <div className="py-1" role="none">
                              <li
                                className="rounded-t flex items-center cursor-pointer text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleMoveToTrash(item?._id, false);
                                }}
                              >
                                <Trash2 className="w-[18px] h-[18px] text-black mr-1" />{" "}
                                Delete
                              </li>
                              <a
                                className="flex items-center text-black rounded-t cursor-pointer w-[200px] text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                                href={`/inbox-single/${item?._id}?print=true`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <AiOutlinePrinter className="w-[20px] h-[20px] text-black mr-1" />{" "}
                                Print
                              </a>
                              <li
                                className="rounded-t flex items-center cursor-pointer w-[220px] text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleMarkUnread(item?._id);
                                }}
                              >
                                <Mail className="mr-1 w-[20px] h-[20px]" /> Mark
                                unread from here
                              </li>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="bg-[#f0f9ff] md:p-5 p-3 md:p-10">
                    <div className="mb-4">
                      {/* <h3 className="md:text-[20px] text-base font-semibold text-gray-700 mb-2 md:mb-4">{item?.subject}</h3> */}
                      <p
                        className="text-sm font-normal text-gray-700 mb-2"
                        dangerouslySetInnerHTML={{ __html: item?.body }}
                      />
                    </div>

                    {item?.attachments?.length > 0 && (
                      <div className="flex items-center">
                        {item?.attachments?.map((attachment, i) => {
                          const fileExtension = attachment
                            .split(".")
                            .pop()
                            .toLowerCase();

                          const fileName = `attachment-${i}.${fileExtension}`;

                          if (
                            [
                              "jpg",
                              "jpeg",
                              "png",
                              "gif",
                              "bmp",
                              "webp",
                            ].includes(fileExtension)
                          ) {
                            return (
                              <div className="mb-4 relative w-fit mr-6" key={i}>
                                {/* <img
                                  src={attachment}
                                  alt="attachment"
                                  className="max-w-full h-auto"
                                /> */}
                                <FaFileImage size={80} />

                                <div
                                  className="absolute w-full h-full top-[30%] left-[20px]"
                                  onClick={() =>
                                    downloadFile(attachment, fileName)
                                  }
                                >
                                  <MdDownload
                                    size={40}
                                    className="text-[#d4d4d4] cursor-pointer"
                                  />
                                </div>
                                {attachment?.length > 20 ? (
                                  <span>
                                    {attachment?.slice(55, 70)}...
                                    {fileExtension}
                                  </span>
                                ) : (
                                  <span>{attachment}</span>
                                )}
                              </div>
                            );
                          } else if (fileExtension === "pdf") {
                            return (
                              <div className="mb-4 relative w-fit mr-6" key={i}>
                                <FaFilePdf color="red" size={80} />
                                {/* <a
                                  href={attachment}
                                  download={`attachment-${i}.pdf`}
                                  className="text-blue-500"
                                ></a> */}
                                <div
                                  className="absolute w-full h-full top-[30%] left-[20px]"
                                  onClick={() =>
                                    downloadFile(attachment, fileName)
                                  }
                                >
                                  <MdDownload
                                    size={40}
                                    className="text-[#d4d4d4] cursor-pointer"
                                  />
                                </div>
                                {attachment?.length > 20 ? (
                                  <span>
                                    {attachment?.slice(55, 70)}...
                                    {fileExtension}
                                  </span>
                                ) : (
                                  <span>{attachment}</span>
                                )}
                              </div>
                            );
                          } else {
                            return (
                              <div className="mb-4 relative w-fit mr-6" key={i}>
                                {/* <a
                                  href={attachment}
                                  download={`attachment-${i}`}
                                  className="text-blue-500"
                                >
                                </a> */}
                                <FaFile size={80} className="text-[#65b1f3]" />
                                <div
                                  className="absolute w-full h-full top-[30%] left-[20px]"
                                  onClick={() =>
                                    downloadFile(attachment, fileName)
                                  }
                                >
                                  <MdDownload
                                    size={40}
                                    className="text-[#d4d4d4] cursor-pointer"
                                  />
                                </div>

                                {attachment?.length > 20 ? (
                                  <span>
                                    {attachment?.slice(55, 70)}...
                                    {fileExtension}
                                  </span>
                                ) : (
                                  <span>{attachment}</span>
                                )}
                              </div>
                            );
                          }
                        })}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            {handleShowReply && (
              <div
                className="mt-10 rounded-xl p-2"
                style={{
                  boxShadow:
                    "0 1px 2px 0 rgba(60,64,67,.3),0 2px 6px 2px rgba(60,64,67,.15)",
                }}
              >
                <span className="text-sm text-gray-500 flex items-center m-2 break-all">
                  <Reply className="mr-1" /> {email?.to}
                </span>
                {/* <textarea
                  className="border-1 outline-none rounded-xl p-2"
                  rows={5}
                  style={{ width: "100%" }}
                  onChange={(e) => setReply(e.target.value)}
                /> */}
                <ReactQuill
                  value={body}
                  onChange={handleReplyInput}
                  modules={modules}
                  formats={formats}
                  bounds={".app"}
                  placeholder="Write something..."
                  onImageUpload={handleImageUpload}
                  // style={{ height: '250px' }}
                />
                {/* <button
                  className={` bg-[#063c66] text-white py-2 p-1 px-3 mt-1 font-bold rounded-md ${
                    reply.trim() === ""
                      ? "bg-[#063c66c4]"
                      : "hover:bg-[#0e4d7f]"
                  }`}
                  onClick={handleEmailReply}
                  disabled={reply.trim() === ""}
                >
                  Reply
                </button> */}

                {attachments?.length > 0 && (
                  <div>
                    {attachments?.map((attachment, i) => {
                      return (
                        <div
                          className="flex items-center bg-[#f5f5f5] w-fit px-2 mt-2"
                          key={i}
                        >
                          <p className="mb-0">{attachment.name}</p>
                          <X
                            className="w-[16px] h-[16px] font-bold mt-1 ml-3 cursor-pointer rounded-sm"
                            onClick={() => handleRemoveFile(attachment.name)}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="flex items-center justify-between">
                  <div className="flex items-center mt-2">
                    <button
                      onClick={() => fileInputRef.current.click()}
                      className="flex items-center space-x-2 mr-2"
                    >
                      <IoAttach className="w-[20px] h-[20px] text-black" />
                      <input
                        type="file"
                        ref={fileInputRef}
                        className="hidden"
                        multiple
                        onChange={handleFileChange}
                      />
                    </button>
                    {/* <button>
                <MdInsertLink className="w-[20px] h-[20px] text-black" />
              </button> */}
                    {/* <button className="mr-2" onClick={() => handleOpenPicker()}>
                      <TbBrandGoogleDrive className="w-[20px] h-[20px] text-black" />
                    </button> */}

                    <button
                      className="flex items-center bg-[#063c66] text-white  py-2 px-4 rounded-md hover:bg-[#0e4d7f]"
                      onClick={handleEmailReply}
                      disabled={loading}
                    >
                      {loading ? "Sending..." : "Send"}
                    </button>
                  </div>
                  <Trash2
                    className="mt-2 mr-2 w-[18px] h-[18px] cursor-pointer"
                    onClick={() => {
                      setHandleShowReply(false);
                      setBody("");
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Trash Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to move this email to trash?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => handleMoveToTrash(emailToTrash, true)}
            >
              Yes
            </Button>
            <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default InboxSingle;
