import React, { useRef, useState } from "react";
import { FiMinus } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { MdFullscreen } from "react-icons/md";
import "./style.css";
// import Linknew from "../../images/link.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { draftEmail, userSendMail } from "../../Api/ExportApi";
import ToastHandle from "../../Components/helpers/ToastMessage";
import { IoAttach, IoTrashOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";

const ComposeEmailModel = ({
  showModal,
  closeModel,
  handleMinimizeMode,
  minimizeMode,
  expandModeParent,
  handleExpandModeParent,
  draftValues,
  storedValues,
}) => {
  const [values, setValues] = useState({
    to: draftValues?.to,
    subject: draftValues?.subject,
    cc: draftValues?.cc,
    bcc: draftValues?.bcc,
    id:draftValues?.id
  });

  const [errors, setErrors] = useState({
    to: "",
    cc: "",
    bcc: "",
  });

  const [expandMode, setExpandMode] = useState(false);

  const fileInputRef = useRef(null);

  const [body, setBody] = useState(draftValues?.body);
  const [attachments, setAttachments] = useState([]);
  const [showCC, setShowCC] = useState(false);
  const [showBCC, setShowBCC] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;

    setErrors({
      to: "",
      cc: "",
      bcc: ""
    });
    
    setValues({
      ...values,
      [name]: value,
    });
  };

  const validateEmails = (emailInput) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    let emails = [];
    
    if (Array.isArray(emailInput)) {
      emails = emailInput.join(',').split(',').map((email) => email.trim());
    } else if (typeof emailInput === 'string') {
      emails = emailInput.split(',').map((email) => email.trim());
    } else {
      return false;
    }
  
    return emails.every((email) => emailRegex.test(email));
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ size: [] }],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "list",
    "bullet",
    "indent",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "align",
    "link",
    "image",
    "video",
  ];

  const handleSendEmail = async (e) => {
    e.preventDefault();

    const newErrors = {};

    if (!validateEmails(values.to)) {
      newErrors.to = "Please enter valid email address.";
    }
    if (showCC && !validateEmails(values.cc)) {
      newErrors.cc = "Please enter valid email address.";
    }
    if (showBCC && !validateEmails(values.bcc)) {
      newErrors.bcc = "Please enter valid email address.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);
    const resp = await userSendMail(
      values.to,
      values.subject || "",
      values.cc || "",
      values.bcc || "",
      body || "",
      attachments,
      draftValues?.id ? draftValues?.id : null
    );

    if (resp.status === 200) {
      // ToastHandle("Email sent successfully", "success");
      toast.success("Email sent successfully");
      setLoading(false);
      setValues({
        to: "",
        subject: "",
        cc: [],
        bcc: [],
      });
      setBody("");
      setAttachments([]);
      closeModel();
      setShowCC(false);
      setShowBCC(false);
    } else {
      // ToastHandle("Something went wrong", "danger");
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const handleDraftEmail = async () => {
    const resp = await draftEmail(
      values.to || "",
      values.subject || "",
      values.cc || "",
      values.bcc || "",
      body || "",
      draftValues?.id 
    );

    if (resp.status === 200) {
      setValues({
        to: "",
        subject: "",
        cc: [],
        bcc: [],
      });
      setBody("");
      setAttachments([]);
      setErrors({
        to: "",
        cc: "",
        bcc: ""
      });
      // ToastHandle("Email drafted", "success");
      setTimeout(() => {
        window.location.reload();
    }, 1000); // 1 second delay
    
 
      toast.success("Email drafted");
    } else {
      // ToastHandle("Something went wrong", "danger");
      toast.error("Something went wrong");
    }
  };

  const handleFileChange = (event) => {
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
    const files = Array.from(event.target.files);

    // Filter files based on the size limit
    const validFiles = files.filter((file) => file.size <= MAX_FILE_SIZE);

    // Check for invalid files
    const invalidFiles = files.filter((file) => file.size > MAX_FILE_SIZE);

    if (invalidFiles.length > 0) {
      // ToastHandle(
      //   `Some files exceed the 5MB limit and were not added.`,
      //   "danger"
      // );
      toast.error( `Some files exceed the 5MB limit and were not added.`);
      setAttachments([]);
    }

    // Add valid files to attachments
    if (validFiles.length > 0) {
      setAttachments((prevAttachments) => [...prevAttachments, ...validFiles]);
    }
  };

  const handleRemoveFile = (fileName) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((file) => file.name !== fileName)
    );
  };


  return (
    <>
      {showModal && !minimizeMode && (
        <>
          {" "}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div
              className={`relative popup-custom customComposeEmail my-6 mx-auto ${
                expandMode &&
                "w-[90%] sm:!w-[90%] md:!w-[80%] lg:!w-[80%]  xl:!w-[70%]"
              }  w-[90%] sm:w-[70%] md:w-[60%] lg:w-[60%]  xl:w-[50%] z-50 rounded-xl`}
            >
              {/*content*/}
              <div
                className={`border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full`}
              >
                {/*header*/}
                <div className="header flex items-start justify-between rounded py-2 px-3 bg-[#eee] ">
                  <h5 className="text-[#333] font-semibold text-[14px]">
                    New Message
                  </h5>
                  <div className="flex gap-0 items-center">
                    <button className="ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
                      <span className="bg-transparent  text-[#333] h-6 w-6 text-xl block outline-none focus:outline-none">
                        <FiMinus
                          onClick={() => {
                            setErrors({
                              to: "",
                              cc: "",
                              bcc: ""
                            });
                            handleMinimizeMode(!minimizeMode)
                          }}
                        />
                      </span>
                    </button>
                    <button className="ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
                      <span className="bg-transparent text-[#333] h-6 w-6 text-xl block outline-none focus:outline-none">
                        <MdFullscreen
                          onClick={() => {
                            setExpandMode(!expandMode);
                            handleExpandModeParent(!expandMode);
                          }}
                        />
                      </span>
                    </button>
                    <button
                      onClick={() => {
                        setExpandMode(false);
                        handleExpandModeParent(false);
                        closeModel();
                        setShowCC(false);
                        setShowBCC(false);
                        setErrors({
                          to: "",
                          cc: "",
                          bcc: ""
                        });
                        if (values.to || values.subject || body) {
                          handleDraftEmail();
                        }
                      }}
                      className="close-btn ml-auto bg-transparent border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    >
                      <span className="bg-transparent text-[#333] h-6 w-6 text-xl block outline-none focus:outline-none">
                        <IoMdClose />
                      </span>
                    </button>
                  </div>
                </div>
                {/*body*/}
                <form onSubmit={handleSendEmail}>
                  <div className="flex flex-col justify-between h-full overflow-y-scroll max-h-[600px]">
                    <div className="model-body relative px-4">
                      <div className="model-body-inner flex flex-col">
                        <div className="relative w-full">
                          <div className="flex gap-3 absolute right-0 bottom-[5px]">
                            {!showCC && (
                              <button
                                className="text-grey text-[14px] hover:underline"
                                onClick={() => setShowCC(!showCC)}
                              >
                                Cc
                              </button>
                            )}
                            {!showBCC && (
                              <button
                                className="text-grey text-[14px] hover:underline"
                                to=""
                                onClick={() => setShowBCC(!showBCC)}
                              >
                                Bcc
                              </button>
                            )}
                          </div>
                          <input
                            type="text"
                            className={`md:w-full border-b focus:outline-0 py-2.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6
                              ${!showCC || !showBCC ? 'w-[80%]' : 'w-full'}`}
                            name="to"
                            value={values?.to}
                            placeholder="To"
                            onChange={handleChange}
                            required
                          />
                          {errors.to && (
                            <p className="text-red-500">{errors.to}</p>
                          )}
                          {showCC && (
                            <>
                              <input
                                type="text"
                                className={`md:w-full border-b focus:outline-0 py-2.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6
                                   ${!showBCC ? 'w-[80%]' : 'w-full'}`}
                                name="cc"
                                value={values?.cc}
                                placeholder="Cc"
                                onChange={handleChange}
                                required
                              />
                              {errors.cc && (
                                <p className="text-red-500">{errors.cc}</p>
                              )}
                            </>
                          )}
                          {showBCC && (
                            <>
                              <input
                                type="text"
                                className="w-full border-b focus:outline-0 py-2.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                name="bcc"
                                value={values?.bcc}
                                placeholder="Bcc"
                                onChange={handleChange}
                                required
                              />
                              {errors.bcc && (
                                <p className="text-red-500">{errors.bcc}</p>
                              )}
                            </>
                          )}
                        </div>
                        <input
                          type="text"
                          name="subject"
                          className="border-b focus:outline-0  py-2.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6"
                          placeholder="Subject"
                          value={values?.subject}
                          onChange={handleChange}
                          required
                        />
                        {/* <textarea className="h-[300px] focus:outline-0 py-2.5 text-gray-900  sm:text-sm sm:leading-6"></textarea> */}
                        <div className="w-full mb-3 mt-5">
                          <div>
                            <ReactQuill
                              value={body}
                              onChange={(val) => {
                                setBody(val);
                              }}
                              modules={modules}
                              formats={formats}
                              bounds={".app"}
                              placeholder="Write something..."
                              // onImageUpload={handleImageUpload}
                              className={`${expandMode ? "expandQuill" : ""}`}
                            />
                          </div>
                        </div>

                        <div>
                          {attachments?.length > 0 && (
                            <div className="mt-4">
                              {attachments?.map((attachment, i) => {
                                return (
                                  <div
                                    className="flex items-center bg-[#f5f5f5] w-fit px-2 mt-2"
                                    key={i}
                                  >
                                    <p className="mb-0">{attachment.name}</p>
                                    <RxCross2
                                      className="w-[16px] h-[16px] font-bold mt-1 ml-3 cursor-pointer rounded-sm"
                                      onClick={() =>
                                        handleRemoveFile(attachment.name)
                                      }
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/*footer*/}
                    {/* <div className="flex items-center justify-between px-6 py-3 bg-white shadow border-solid border-blueGray-200">
              <div className="flex items-center justify-between gap-2 w-full mob-section">
                <div className="flex lg:flex-wrap 2xl:flex-nowrap bottom-bar rounded px-3 py-1 ">
                  <div className="border-r flex gap-3 items-center pe-3">
                    <Link className="hover:bg-gray-200 p-2" to="">
                      <PiArrowUUpLeftBold />
                    </Link>
                    <Link to="">
                      <PiArrowUUpRightBold />
                    </Link>
                  </div>
                  <div className="border-r flex gap-2 items-center pe-3">
                    <p className="flex gap-1 items-center hover:bg-gray-200 p-2">
                      Sans serif
                      <RxTriangleDown />
                    </p>
                  </div>
                  <div className="border-r flex  items-center pe-3">
                    <img className="hover:bg-gray-200 p-2" src={TT} />
                    <RxTriangleDown />
                  </div>
                  <div className="border-r flex gap-0 items-center pe-3">
                    <img className="hover:bg-gray-200 p-2" src={Bold} />
                    <img className="hover:bg-gray-200 p-2" src={Italic} />
                    <img className="hover:bg-gray-200 p-2" src={Underline} />
                  </div>
                  <div className="border-r flex  items-center pe-3">
                    <img className="hover:bg-gray-200 p-2" src={Textformat} />
                    <RxTriangleDown />
                  </div>
                  <div className="border-r flex gap-2 items-center pe-3">
                    <img className="hover:bg-gray-200 p-2" src={Align} />
                  </div>
                  <div className="border-r flex gap-2 items-center pe-3">
                    <img className="hover:bg-gray-200 p-2" src={Numberlist} />
                  </div>
                  <div className="border-r flex gap-2 items-center pe-3">
                    <img className="hover:bg-gray-200 p-2" src={Bulletlist} />
                  </div>
                  <div className="border-r flex gap-2 items-center pe-3">
                    <img className="hover:bg-gray-200 p-2" src={Indentless} />
                  </div>
                  <div className="flex gap-2 items-center pe-3">
                    <img className="hover:bg-gray-200 p-2" src={Indentmore} />
                  </div>
                </div>
                <div className="">
                  <IoTrashOutline className="text-xl" />
                </div>
              </div>
            </div> */}
                    <div className="bottom-ineer rounded px-4 py-2 flex item-center justify-between">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <button
                            type="button"
                            onClick={() => fileInputRef.current.click()}
                            className="flex items-center space-x-2 mr-2"
                          >
                            <IoAttach className="w-[23px] h-[23px] text-black" />
                            <input
                              type="file"
                              ref={fileInputRef}
                              className="hidden"
                              multiple
                              onChange={handleFileChange}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="bg-[#2a79bd] disabled:bg-[#3779b3]  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                        type="submit"
                        disabled={
                          values?.to?.length < 1 ||
                          values?.subject?.trim() === "" ||
                          body === undefined ||
                          body === '<p><br></p>' ||
                          body === '' ||
                          loading
                        }
                      >
                        {loading ? "Wait..." : "Send"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
};

export default ComposeEmailModel;
